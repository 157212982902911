import React, { useEffect, useState } from "react";
import "./NFTCard.css";
import { FaEthereum } from "react-icons/fa";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { ColorExtractor } from 'react-color-extractor'
import Card from "./Card";
import Button from "./Button";
import { Colors } from "./Colors";
import Zeus from "./ZEUS2.jpg"
import PLUR from "./plurfav.png"
import { useNavigate} from "react-router-dom"
import PC from "./PLURcoin.png"
import Angel from "./Angelz.png"
import PLURA from "./plurable.png"
import VAULT from "./vault.png"



import { ModelViewerElement } from "@google/model-viewer";




const VaultCard = ({ username, nftName, price, nftSrc, likeCount, gradient, onClick }) => {
  const [isLike, setIsLike] = useState(false);
  const [colors, setColors] = useState([]);


  const like = () => setIsLike(!isLike);

  const getColors = colors => {
    setColors(c => [...c, ...colors]);
    //console.log(colors);
  }

  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `/Plurthem`; 
    navigate(path);
  }



  return (
    <Card onClick={event => window.location.href='https://plurvault.netlify.app/'}
      blurColor={colors[0]}

      child={<>
        <model-viewer ar-scale="auto" ar ar-modes="webxr scene-viewer quick-look" id="reveal" loading="eager" camera-controls auto-rotate src={nftSrc} > </model-viewer> : <><ColorExtractor >
          <img className="nft-image" src={VAULT} type= "video/mp4" style={{objectFit: "cover"}}  />
        </ColorExtractor></>
        <div className="wrapper">
          <div className="info-container">
         
            <p className="name">PLUR_VAULT</p>
            <p className="owner">  Stake PLUR receive PLUR! Stake your PLURcoin and receive 25% APY. Minimum term 1 month. </p>
          </div>

          <div className="price-container">
            
   
          </div>
        </div>
        <div className="buttons">
          {/* <button className="buy-now">Buy Now</button> */}
      
          <div className="like-container">

        
          </div>
        </div>
      </>}>

    </Card>
  );
};

export default VaultCard;