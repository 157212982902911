import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./nvbutton.css"
import "./mintbutton.css"
import "./footer.css"
import "./navlinks.css"
import "./contractlink.css"
import "./metamobile.css"

import Home from "./components/home/home";
import {
  BrowserRouter,
  Routes,
  Route,

} from "react-router-dom";















const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;





function App() {

  const [loading, setLoading] = useState(true);
  const spinner = document.getElementById("spinner");
  if (spinner) {
    setTimeout(() => {
      spinner.style.display = "none";
      setLoading(false);
    }, 3000);
  }
  
  return(

    !loading && (
      <BrowserRouter>
          <Routes basename='/index.html'>
            <Route exact path="/" element={<Home />} />

            
       

          </Routes>
      </BrowserRouter>
    )
  )


}

export default App;
