import React, { useEffect, useState, useRef } from "react";
import {FaTwitter, FaFacebook, FaInstagram, FaDiscord} from "react-icons/fa"
import {IconContext} from "react-icons"
import 'bootstrap/dist/css/bootstrap.min.css';
import {Navbar, Nav, Container, NavDropdown, Button } from "react-bootstrap"
import { MDBFooter,MDBContainer,MDBCol,MDBRow,MDBIcon, MDBNavbar,MDBNavbarBrand,MDBNavbarLink, } from 'mdb-react-ui-kit';
import "./nvbutton.css"
import PLUR from "./PLUR.png"
import "./foot.css"
import Fade from 'react-reveal/Fade';







export default function Footer() {
    return (
      <Fade bottom>
        <MDBFooter className='text-center text-""'
      style={{
        backgroundColor: "black"
      }}> 
      
      <MDBContainer className='p-4 pb-0'>
      
        <section className='mb-4'>
          <a style={{color: "white", fontSize: "20px", border: "2px solid #fff",color: "#fff",borderRadius: "50%"}} target = {"_blank"} className='btn btn-outline-black btn-floating m-1' href='https://discord.gg/SY4DTqFcNt' role='button'>
            <FaDiscord fab icon='facebook-f' />
          </a>

          <a style={{color: "white", fontSize: "30px", fontSize: "20px", border: "2px solid #fff",color: "#fff",borderRadius: "50%"}}  target = {"_blank"} className='btn btn-outline-black btn-floating m-1' href='https://twitter.com/PLURcoin_' role='button'>
            <FaTwitter fab icon='google' />
          </a>
          
          <a style={{color: "white", fontSize: "30px", fontSize: "20px", border: "2px solid #fff",color: "#fff",borderRadius: "50%"}}  target = {"_blank"} className='btn btn-outline-black btn-floating m-1' href='https://www.instagram.com/plur_coin/' role='button'>
            <FaInstagram fab icon='google' />
          </a>
        </section>
      </MDBContainer> 
      <div style={{ fontSize:"12px", color: "white",}}> Powered by</div>
      <a target = {"_blank"} href='https://plurcoin.com'><img src={PLUR} style={{height: "50px", width: "140px", marginBottom: "8px"}}  ></img></a>
    </MDBFooter>
    </Fade>
    )
}




      

