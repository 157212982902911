import React, { useState } from "react";
import "./card.css";

const Card = React.forwardRef(
  (
    {
      width = "250px",
      height = "300px",
      child,
      blurColor = "transparent",
      onClick,
    },
    ref
  ) => (
    <div
      className="card"
      style={{
        width: `${width}`,
        height: `${height}`,
        background: "transparent",
      }}
      onClick={onClick}
      ref={ref}
    >
      {child}
    </div>
  )
);
export default Card;
