import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { icons } from "react-icons";
import {FaTwitter, FaFacebook, FaInstagram, FaDiscord} from "react-icons/fa"
import {IconContext} from "react-icons"
import 'bootstrap/dist/css/bootstrap.min.css';
import {Navbar, Nav, Container, NavDropdown, Button } from "react-bootstrap"
import "./nvbutton.css"
import "./mintbutton.css"
import "./footer.css"
import "./navlinks.css"
import "./contractlink.css"
import "./metamobile.css"
import "./cardlist.css"
import { MDBFooter,MDBContainer,MDBCol,MDBRow,MDBIcon, MDBNavbar,MDBNavbarBrand,MDBNavbarLink, } from 'mdb-react-ui-kit';
import PLURvid from "./plurvid.MP4"
import NFTCard from "./NFTCard";
import Navigation from "../navbar/navbar"
import Header from "../navbar/navbar";
import Footer from "../footer/footer";
import SorenNFTCard from "./SorenNFTCard";
import AngelzCard from "./AngelzCard";
import PluraCard from "./PLURableCard";
import VaultCard from "./Vault";
import Bounce from 'react-reveal/Bounce';
import Fade from 'react-reveal/Fade';






const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: light;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;


export const StyledRoundButton = styled.button`
  color: blue;
  padding: 10px;
  border-radius: 50%;
  border: none;
  background-color: white;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 8px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 80%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const ResponsiveWrapper2 = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 80%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledLogoText = styled.img`
  display: block;
  max-width:200px;
  max-height:80px;
  width: auto;
  height: auto;
`;


export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 0px solid var(--secondary);
  background-color: #0a61b2;
  border-radius: 5%;
  width: 400px;
  resizeMode: 'contain',
  @media (min-width: 1000px) {
    width: 400px;
  }
  @media (min-width: 1000px) {
    width: 490px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
  hover: white;
`;

export const StyledLink2 = styled.a`
  color: var(--secondary);
  text-decoration: none;
  hover: white;
`;


export const StyledIcon = styled.a`
  text-decoration: none;
`;



function Home() {
  const [isOpen, setIsOpen] = useState(false);
 
  const togglePopup = () => {
    setIsOpen(!isOpen);
  }
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: true,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(blockchain.account, mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, thank you for supporting ${CONFIG.NFT_NAME}! go visit OpenSea to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 100) {
      newMintAmount = 100;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/configplur/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };


  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);



  return (
            
    <s.Screen>  
    
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "black" }}
        image={CONFIG.SHOW_BACKGROUND ? "/configplur/images/bgplur.jpeg" : null}
      >
        <ResponsiveWrapper flex={2} style={{ padding: 12 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
          <Fade left>
            <s.TextTitle
              style={{
                marginTop: "30px",
                objectFit: "contain",
                
                color: "white",
                fontSize: "50px",
                lineHeight: "1em",
                textAlign: "center",
                whiteSpace: "normal",
                
                
              }}
            >
              THE FUTURE IS   
            </s.TextTitle>
            </Fade>
            <Fade right>
            <s.TextTitle
              style={{
                
                marginTop: "5px",
                objectFit: "contain",
                fontFamily: "pump",
                color: "#d00087",
                fontSize: "70px",
                lineHeight: "1em",
                textAlign: "center",
                whiteSpace: "normal",
               
                
                
              }}
            >
              PLUR   
            </s.TextTitle>
            </Fade>
      
        


            <s.SpacerMedium />
            <div className="cardList">
            <Fade left>
            <NFTCard></NFTCard>
            <AngelzCard></AngelzCard>
            </Fade>
            <Fade right>
            <PluraCard></PluraCard>
            <VaultCard></VaultCard>
            </Fade>
            </div>
            <s.SpacerMedium />


            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ fontFamily: "Organic Relief", textAlign: "center", color: "var(--accent-text)" }}
                >
                  SOLD OUT.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>

                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
  

                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton classsName='rounded'
                        style={{                       
                        fontFamily: "Organic Relief",
                        color: "#d00087", lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton classsName='rounded'             
                      style={{
                      fontFamily: "Organic Relief",
                      color: "#d00087"
            }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <Button className="mintButton"  variant="Primary"
                                  style={{
                                    fontFamily: "Organic Relief",
                                  }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" : "BUY"}
                      </Button>
                    </s.Container>
                  </>
                )}
              </>
            )}

          </s.Container>

          </ResponsiveWrapper>
          <s.SpacerSmall />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
      </s.Container>
      </s.Container>

      
    <Footer />

    </s.Screen>
    
  );
}

export default  Home;
