import React, { useEffect, useState } from "react";
import "./NFTCard.css";
import { FaEthereum } from "react-icons/fa";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { ColorExtractor } from 'react-color-extractor'
import Card from "./Card";
import Button from "./Button";
import { Colors } from "./Colors";
import Zeus from "./ZEUS2.jpg"
import PLUR from "./plurfav.png"
import { useNavigate} from "react-router-dom"
import PC from "./plur.png"



import { ModelViewerElement } from "@google/model-viewer";




const NFTCard = ({ username, nftName, price, nftSrc, likeCount, gradient, onClick }) => {
  const [isLike, setIsLike] = useState(false);
  const [colors, setColors] = useState([]);


  const like = () => setIsLike(!isLike);

  const getColors = colors => {
    setColors(c => [...c, ...colors]);
    //console.log(colors);
  }

  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = "https://www.plurcoin.com/"; 
    navigate(window.location);
  }



  return (
  
    <Card onClick={event => window.location.href='https://www.plurcoin.com/'} 
      blurColor={colors[0]}

      child={<>
        <model-viewer ar-scale="auto" ar ar-modes="webxr scene-viewer quick-look" id="reveal" loading="eager" camera-controls auto-rotate src={nftSrc} > </model-viewer> : <><ColorExtractor >
          <img className="nft-image" src={PC} style={{objectFit: "cover"}}  />
        </ColorExtractor></>
        <div className="wrapper">
          <div className="info-container">
         
            <p className="name">PLURcoin</p>
            <p className="owner">  A new eco-friendly, socially responsible cryptocurrency founded on the values of Peace, Love, Unity & Respect!</p>
          </div>
          

          <div className="price-container">
            
   
          </div>
        </div>
        <div className="buttons">
          {/* <button className="buy-now">Buy Now</button> */}
           
          <div className="like-container">

        
          </div>
        </div>
      </>}>

    </Card>
  );
};

export default NFTCard;